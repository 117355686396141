import React from 'react';

function ArticlePreview() {
  return (
    <div>
    hit from ArticlePreview
    </div>
  );
}

export default ArticlePreview;
